import React, { useContext } from 'react';
import {
  StyledPopupOverlay,
  StyledPopup,
  StyledPopupWrapper,
  StyledPopupActions,
  StyledCancelBtn,
  StyledCloseBtn,
} from './Popup.styles';
import modalClose from '../../assets/close.svg';
import texts from '../../texts';
import { LangContext } from '../../contexts/LangContext';

const Popup = ({ text, closeFn }) => {
  const { lang } = useContext(LangContext);

  let message = texts[lang].errorIneligable;

  if (text.includes('new')) {
    message = texts[lang].errorText;
  }

  return (
      <>
        <StyledPopupOverlay />
        <StyledPopupWrapper>
          <StyledPopup>
            <StyledCloseBtn onClick={closeFn} type="button">
              <img
                src={modalClose}
                alt="close"
              />
            </StyledCloseBtn>
            <p>{message}</p>
            <StyledPopupActions>
              <StyledCancelBtn onClick={closeFn}>
                {texts[lang].modalAccept}
              </StyledCancelBtn>
            </StyledPopupActions>
          </StyledPopup>
        </StyledPopupWrapper>
      </>
    );
};

export default Popup;
