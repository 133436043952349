import React, { useContext } from 'react';
import {
  StyledPopupOverlay,
  StyledPopup,
  StyledPopupWrapper,
  StyledCloseBtn,
} from './Popup.styles';
import modalClose from '../../assets/close.svg';
import texts from '../../texts';
import { LangContext } from '../../contexts/LangContext';
import howGoals from '../../assets/how-goals-2.png';

const Popup = ({ closeFn }) => {
  const { lang } = useContext(LangContext);

  return (
      <>
        <StyledPopupOverlay />
        <StyledPopupWrapper className='how'>
          <StyledPopup>
            <StyledCloseBtn onClick={closeFn} type="button">
              <img
                src={modalClose}
                alt="close"
              />
            </StyledCloseBtn>
            {texts[lang].howText.map(item => (<p>{item}</p>))}
            <ol>
              {texts[lang].howList.map((item, index) => (
                <li key={index}>
                  {item.text}
                  {item.image === 'how-goals' && (
                    <img src={howGoals} alt={item.text} />
                  )}
                </li>
              ))}
            </ol>
          </StyledPopup>
        </StyledPopupWrapper>
      </>
    );
};

export default Popup;
