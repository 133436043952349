const texts = {
  en: {
    language: 'English',
    subtitle: '"Welcome to Fortnite" Challenge',
    stageOne: 'Create your Epic account',
    stageTwo: 'Gain {total} seasonal levels to support {streamer}\'s stream!',
    stageTwoProgress: 'Your current progress: {current}/{total} levels',
    stageTwoHowTo: 'Click to learn how to gain Fortnite Seasonal Levels.',
    stageTwoWarning: 'Stats may take up to 90 minutes to update.',
    stageThree: 'Earn the in-game "Gimme Moire" Wrap',
    btnText: 'Join',
    deleteText: 'Remove me from the "Welcome to Fortnite" Challenge',
    notes: [
      'Rewards will be granted directly to the recipient\'s Epic account within one (1) hour of reaching the relevant reward level, or such other time as reasonably required by Epic for such grant.',
      'NOTE: The "Gimme Moire" in-game Cosmetic Wrap will be also available for purchase at a later date.',
    ],
    modalText: 'ONCE YOU REMOVE YOUR ACCOUNT FROM THE "WELCOME TO FORTNITE" CHALLENGE, YOUR CHALLENGE PROGRESS WILL BE DELETED. ARE YOU SURE YOU WANT TO LEAVE?',
    modalApproveText: 'REMOVE',
    modalDenyText: 'CANCEL',
    modalAccept: 'OK',
    errorText: 'UNFORTUNATELY, THE ACCOUNT YOU CONNECTED HAS HAD ACTIVITY IN THE LAST 30 DAYS AND THEREFORE CANNOT BE PART OF THE "WELCOME TO FORTNITE" CHALLENGE.',
    errorIneligable: 'YOU ARE NOT ELIGIBLE TO BE PART OF THE EVENT. PLEASE REFER TO THE FAQ PAGE TO SEE ELIGIBILITY TERMS.',
    howText: [
      'SEASONAL LEVELS ARE HOW MANY LEVELS YOU ADVANCE IN THE CURRENT BATTLE ROYALE SEASON. SEASONAL LEVELS CAN BY GAINED BY EARNING IN-GAME EXPERIENCE (XP) OR BY PURCHASING THEM VIA THE BATTLE PASS PAGE IN FORTNITE.',
      'YOU CAN SEE YOUR CURRENT SEASONAL LEVEL IN THE LOBBY. YOU CAN EARN XP IN VARIOUS WAYS:'
    ],
    howList: [
      {
        text: 'COMPLETING DAILY, WEEKLY, AND MILESTONE QUESTS.',
      },
      {
        text: 'COMPLETING IN-GAME ACTIONS IN FORTNITE.',
        image: 'how-goals',
      },
    ],
    footer: {
      createdBy: 'Created with ❤️ by StreamElements © All rights reserved',
      contact: 'Contact us',
      privacy: 'Privacy Policy',
      faq: 'FAQ',
      faqLink: 'https://drive.google.com/file/d/1G9asLBM8ANzBKFiUtjN655NaGfSnG78c/view'
    },
  },
  fr: {
    language: 'French',
    subtitle: '"Welcome to Fortnite" Challenge',
    stageOne: 'Créez votre compte Epic',
    stageTwo: 'Gagnez {total} niveaux de saison pour soutenir les diffusions de {streamer} !',
    stageTwoProgress: 'Progression actuelle : {current}/{total}',
    stageTwoHowTo: 'Cliquez ici pour savoir comment gagner des niveaux de saison dans Fortnite.',
    stageTwoWarning: 'L\'actualisation des statistiques peut prendre jusqu\'à 90 minutes.',
    stageThree: 'Gagnez le revêtement Effet moiré dans le jeu',
    btnText: 'Participer',
    deleteText: 'RETIRER MON COMPTE DU "WELCOME TO FORTNITE" CHALLENGE',
    notes: [
      'LES RÉCOMPENSES SERONT DIRECTEMENT REMISES SUR LE COMPTE EPIC DU BÉNÉFICIAIRE DANS L\'HEURE QUI SUIT L\'OBTENTION DU NIVEAU REQUIS POUR LA RÉCOMPENSE, OU DANS LE DÉLAI JUGÉ RAISONNABLE PAR EPIC.',
      'REMARQUE : LE REVÊTEMENT COSMÉTIQUE EFFET MOIRÉ DANS LE JEU POURRA ÊTRE PROPOSÉ À L\'ACHAT À UNE DATE ULTÉRIEURE.',
    ],
    modalText: 'UNE FOIS VOTRE COMPTE RETIRÉ DU "WELCOME TO FORTNITE" CHALLENGE, VOTRE PROGRESSION DANS LE DÉFI SERA SUPPRIMÉE. VOULEZ-VOUS VRAIMENT VOUS RETIRER ?',
    modalApproveText: 'CONFIRMER',
    modalDenyText: 'ANNULER',
    modalAccept: 'OK',
    errorText: 'MALHEUREUSEMENT, LE COMPTE QUE VOUS AVEZ CONNECTÉ A EU UNE ACTIVITÉ AU COURS DES 30 DERNIERS JOURS ET NE PEUT DONC PAS PARTICIPER AU "WELCOME TO FORTNITE" CHALLENGE.',
    errorIneligable: 'VOUS N\'ÊTES PAS ÉLIGIBLE POUR CET ÉVÉNEMENT. CONSULTEZ LA FAQ POUR CONNAÎTRE LES CONDITIONS D\'ÉLIGIBILITÉ.',
    howText: [
      'LES NIVEAUX DE SAISON CORRESPONDENT AU NOMBRE DE NIVEAUX DE VOTRE AVANCEMENT DANS LE PASSE DE COMBAT DE LA SAISON EN COURS. LES NIVEAUX DE SAISON PEUVENT ÊTRE OBTENUS EN GAGNANT DE L\'EXPÉRIENCE (EXP) DANS LE JEU OU EN LES ACHETANT SUR LA PAGE DU PASSE DE COMBAT DE FORTNITE.',
      'VOUS POUVEZ VOIR VOTRE NIVEAU DE SAISON ACTUEL DANS LE SALON. VOUS POUVEZ GAGNER DE L\'EXP DE PLUSIEURS FAÇONS :'
    ],
    howList: [
      {
        text: 'EN ACCOMPLISSANT DES QUÊTES QUOTIDIENNES, HEBDOMADAIRES ET D\'ÉTAPE.',
      },
      {
        text: 'EN ACCOMPLISSANT DES ACTIONS DANS LE JEU FORTNITE.',
        image: 'how-goals',
      },
    ],
    footer: {
      createdBy: 'Créé avec ❤️ par StreamElements © Tous droits réservés',
      contact: 'Nous contacter',
      privacy: 'Politique de confidentialité',
      faq: 'FAQ',
      faqLink: 'https://drive.google.com/file/d/1ttw_umGzzYbmLd2OgapOKZxEleRv7Q5K/view'
    },
  },
  pt: {
    language: 'Portuguese',
    subtitle: '"Welcome to Fortnite" Challenge',
    stageOne: 'Crie sua conta Epic',
    stageTwo: 'Ganhe {total} Níveis de Temporada para apoiar a transmissão de {streamer}!',
    stageTwoProgress: 'Seu progresso atual: {current}/{total} níveis.',
    stageTwoHowTo: 'CLIQUE AQUI PARA SABER COMO GANHAR\nNÍVEIS DE TEMPORADA NO FORTNITE',
    stageTwoWarning: 'Pode demorar até 90 minutos para a atualização das estatísticas.',
    stageThree: 'Ganhe o Envelopamento "Quero Mais" no jogo',
    btnText: 'Participar',
    deleteText: 'NÃO QUERO PARTICIPAR DO "WELCOME TO FORTNITE" CHALLENGE.',
    notes: [
      'AS RECOMPENSAS SERÃO ENTREGUES DIRETAMENTE À CONTA EPIC DO DESTINATÁRIO DENTRO DE 1 (UMA) HORA APÓS O NÍVEL PARA A RECOMPENSA TER SIDO ALCANÇADO, OU QUALQUER OUTRO PRAZO RAZOÁVEL NECESSÁRIO PARA QUE A EPIC REALIZE A ENTREGA.',
      'OBSERVAÇÃO: O ENVELOPAMENTO COSMÉTICO "QUERO MAIS" TAMBÉM FICARÁ DISPONÍVEL NO JOGO PARA COMPRA NO FUTURO.',
    ],
    modalText: 'AO EXCLUIR SUA CONTA DO "WELCOME TO FORTNITE" CHALLENGE, SEU PROGRESSO TAMBÉM É APAGADO. QUER MESMO ABANDONAR O DESAFIO?',
    modalApproveText: 'PERMITIR',
    modalDenyText: 'CANCELAR',
    modalAccept: 'OK',
    errorText: 'INFELIZMENTE, DETECTAMOS ATIVIDADE NOS ÚLTIMOS 30 DIAS NA CONTA CONECTADA. LOGO, ELA NÃO PODE PARTICIPAR DO "WELCOME TO FORTNITE" CHALLENGE.',
    errorIneligable: 'VOCÊ NÃO É ELEGÍVEL PARA PARTICIPAR DO EVENTO. CONSULTE A PÁGINA DE PERGUNTAS FREQUENTES PARA VER OS REQUISITOS DE PARTICIPAÇÃO.',
    howText: [
      'OS NÍVEIS DA TEMPORADA CORRESPONDEM AOS NÍVEIS QUE VOCÊ ALCANÇOU DURANTE A TEMPORADA ATUAL DO BATTLE ROYALE. NÍVEIS DA TEMPORADA PODEM SER ALCANÇADOS COM EXPERIÊNCIA (EXP) NO JOGO OU COMPRADOS ATRAVÉS DA PÁGINA DO PASSE DE BATALHA NO FORTNITE.',
      'É POSSÍVEL VERIFICAR SEU NÍVEL DA TEMPORADA ATUAL NO LOBBY. HÁ VÁRIAS MANEIRAS DE GANHAR EXP:'
    ],
    howList: [
      {
        text: 'CONCLUINDO TAREFAS DIÁRIAS, SEMANAIS E CUMULATIVAS.',
      },
      {
        text: 'REALIZANDO E CONCLUINDO AÇÕES DENTRO DO FORTNITE.',
        image: 'how-goals',
      },
    ],
    footer: {
      createdBy: 'Criado com ❤️ por StreamElements © Todos os direitos reservados',
      contact: 'Contato',
      privacy: 'Política de Privacidade',
      faq: 'Perguntas Frequentes',
      faqLink: 'https://drive.google.com/file/d/1TK_xeEYedUd_042a6vUYdIeA91Ol6o26/view'
    },
  },
  es: {
    language: 'Spanish',
    subtitle: '"Welcome to Fortnite" Challenge',
    stageOne: 'Crea tu cuenta de Epic',
    stageTwo: 'Consigue {total} niveles de temporada para apoyar el stream de {streamer}',
    stageTwoProgress: 'Tu progreso actual: {current}/{total} niveles',
    stageTwoHowTo: 'Descubre cómo conseguir niveles de temporada en el juego.',
    stageTwoWarning: 'Las estadísticas pueden tardar hasta 90 minutos en actualizarse.',
    stageThree: 'Consigue el envoltorio para el juego "Efecto muaré"',
    btnText: 'Unirse',
    deleteText: 'YA NO QUIERO PARTICIPAR EN EL "WELCOME TO FORTNITE" CHALLENGE.',
    notes: [
      'LAS RECOMPENSAS SE OTORGARÁN DIRECTAMENTE EN LA CUENTA DE EPIC DEL DESTINATARIO UNA (1) HORA DESPUÉS DE HABER ALCANZADO EL NIVEL DE RECOMPENSA EN CUESTIÓN O EN CUALQUIER OTRO PLAZO QUE EPIC ESTIME RAZONABLE PARA DICHA ENTREGA.',
      'AVISO: EL ENVOLTORIO COSMÉTICO PARA EL JUEGO "EFECTO MUARÉ" TAMBIÉN ESTARÁ DISPONIBLE PARA SU COMPRA MÁS ADELANTE.',
    ],
    modalText: 'CUANDO ELIMINES TU CUENTA DE "WELCOME TO FORTNITE" CHALLENGE, SE BORRARÁ TODO TU PROGRESO. ¿SEGURO QUE QUIERES ELIMINARLA?',
    modalApproveText: 'ACEPTAR',
    modalDenyText: 'CANCELAR',
    modalAccept: 'ACEPTAR',
    errorText: 'SENTIMOS DECIRTE QUE LA CUENTA QUE HAS VINCULADO HA ESTADO ACTIVA EN LOS ÚLTIMOS 30 DÍAS Y QUE, POR LO TANTO, NO PUEDE FORMAR PARTE DEL DESAFÍO "WELCOME TO FORTNITE".',
    errorIneligable: 'NO CUMPLES LOS REQUISITOS PARA PARTICIPAR EN EL EVENTO. CONSULTA LA PÁGINA DE PREGUNTAS FRECUENTES PARA LEER LAS CONDICIONES DE PARTICIPACIÓN.',
    howText: [
      'LOS NIVELES DE TEMPORADA SON LOS NIVELES QUE OBTIENES EN LA TEMPORADA ACTUAL DE BATTLE ROYALE. PUEDES CONSEGUIRLOS CON EXPERIENCIA (PE) EN EL JUEGO O ADQUIRIÉNDOLOS EN LA PÁGINA DEL PASE DE BATALLA DE FORTNITE. PUEDES VER TU NIVEL DE TEMPORADA ACTUAL EN LA SALA.',
      'LOS PE SE CONSIGUEN DE VARIAS FORMAS:'
    ],
    howList: [
      {
        text: 'COMPLETANDO MISIONES DIARIAS, SEMANALES Y DE HITOS.',
      },
      {
        text: 'REALIZANDO ACCIONES EN EL JUEGO.',
        image: 'how-goals',
      },
    ],
    footer: {
      createdBy: 'Creado con ❤️ por StreamElements © Todos los derechos reservados',
      contact: 'Contacta con nosotros',
      privacy: 'Política de privacidad',
      faq: 'Preguntas frecuentes',
      faqLink: 'https://drive.google.com/file/d/1zgvhEol14BxbK0I1mUtW97rzkA6yJ0ZT/view'
    },
  },
  // de: {
  //   language: 'Deutsch',
  //   subtitle: '"Welcome to Fortnite" Challenge',
  //   stageOne: 'Erstelle dein Epic-Konto',
  //   stageTwo: 'Erhalte {total} saisonale Level für deinen Twitch-Namen in {streamer}\'s Stream',
  //   stageTwoProgress: 'Dein aktueller Fortschritt: {current}/{total} Level',
  //   stageTwoHowTo: 'Wie steigt man Level im Spiel auf?',
  //   stageTwoWarning: 'Es kann bis zu 90 Minuten dauern, bis Statistiken aktualisiert sind',
  //   stageThree: 'ERHALTE DIE KOSMETISCHE LACKIERUNG MOIRÉ-MUSTER',
  //   btnText: 'Beitreten',
  //   deleteText: 'NICHT MEHR AN DER "WELCOME TO FORTNITE" CHALLENGE TEILNEHMEN',
  //   notes: [
  //     'BELOHNUNGEN WERDEN INNERHALB VON EINER (1) STUNDE, NACHDEM DIE ENTSPRECHENDE BELOHNUNGSSTUFE ERREICHT WURDE, ODER INNERHALB EINES ANDEREN FÜR DIE VERGABE DURCH EPIC BENÖTIGTEN, ANGEMESSENEN ZEITRAUMS DIREKT DEM EPIC-KONTO DES EMPFÄNGERS GUTGESCHRIEBEN.',
  //     'HINWEIS: DIE KOSMETISCHE LACKIERUNG „MOIRÉ-MUSTER“ WIRD AUCH ZU EINEM SPÄTEREN ZEITPUNKT ZUM KAUF ZUR VERFÜGUNG STEHEN.',
  // ],
  //   modalText: 'Sobald du dein Konto aus dem Event löscht, werden deine Daten gelöscht. Bist du sicher, dass du das willst?',
  //   modalApproveText: 'LÖSCHEN',
  //   modalDenyText: 'ABBRECHEN',
  //   errorText: 'Leider war das Konto, das du verbunden hast, in den letzten 30 Tagen aktiv und kann nicht Teil des Events sein.',
  //   howText: 'Level zeigen deine Erfahrung (EP) im Spiel in der aktuellen Season. Du kannst dein aktuelles Level in der Season in der Lobby sehen. Du kannst auf verschiedene Arten EP verdienen:',
  //   howList: [
  //     {
  //       text: 'Abschließen von täglichen, wöchentlichen und Meilenstein-Quests.',
  //     },
  //     {
  //       text: 'Durchführen von Aktionen im Spiel in Fortnite.',
  //       image: 'how-goals',
  //     },
  //   ],
  // },
  // pl: {
  //   language: 'Polski',
  //   subtitle: '"Welcome to Fortnite" Challenge',
  //   stageOne: 'Utwórz konto Epic',
  //   stageTwo: 'ZDOBĄDŹ {total} SEZONOWYCH POZIOMÓW, ABY TWÓJ PSEUDONIM Z TWITCHA POJAWIŁ SIĘ NA STREAMIE UŻYTKOWNIKA {streamer}',
  //   stageTwoProgress: 'Twój bieżący postęp: poziom {current}/{total}',
  //   stageTwoHowTo: 'Kliknij, aby dowiedzieć się, jak zdobywać nowe poziomy w grze.',
  //   stageTwoWarning: 'Aktualizacja statystyk może potrwać do 90 minut.',
  //   stageThree: 'OTRZYMAJ MALOWANIE FALISTY WZÓR',
  //   btnText: 'Dołącz',
  //   deleteText: 'USUŃ MNIE Z WYZWANIA "WELCOME TO FORTNITE" CHALLENGE',
  //   notes: [
  //      'NAGRODY ZOSTANĄ PRZEKAZANE BEZPOŚREDNIO NA TWOJE KONTO EPIC W CIĄGU JEDNEJ (1) GODZINY OD OSIĄGNIĘCIA WYMAGANEGO POZIOMU (LUB W CZASIE NIEZBĘDNYM FIRMIE EPIC DO ICH PRZEKAZANIA).',
  //      'UWAGA: MALOWANIE FALISTY WZÓR BĘDZIE RÓWNIEŻ DOSTĘPNE W SPRZEDAŻY W PÓŹNIEJSZYM TERMINIE.',
  //   ],
  //   modalText: 'Jeśli usuniesz swoje konto z wydarzenia, wszystkie Twoje postępy zostaną usunięte. Na pewno chcesz wyjść?',
  //   modalApproveText: 'USUŃ',
  //   modalDenyText: 'ANULUJ',
  //   errorText: 'Niestety konto, za pomocą którego się połączyłeś, było aktywne w ciągu ostatnich 30 dni i nie może wziąć udziału w wydarzeniu.',
  //   howText: 'Poziomy są odzwierciedleniem Twojego doświadczenia w grze (PD) w bieżącym sezonie. Swój obecny poziom sezonowy możesz zobaczyć w lobby. Możesz zdobyć więcej PD na różne sposoby:',
  //   howList: [
  //     {
  //       text: 'Wykonując Dzienne, Tygodniowe oraz Etapowe Zadania.',
  //     },
  //     {
  //       text: 'Wykonując zadania w Fortnite.',
  //       image: 'how-goals',
  //     },
  //   ],
  // }
};

export default texts