import { useState, useEffect, useContext } from 'react';
import { init, track } from '@streamelements/alchemy';
import { useParams } from 'react-router-dom';
import logo from '../assets/fortnite-logo.png';
import chars from '../assets/landing-page-chars-4.png';
import check from '../assets/check.svg';
import wrap from '../assets/waves-wrap-roll.png';
import texts from '../texts';
import constants from '../constants'
import { ApiContext } from '../contexts/ApiContext' ;
import getLink from '../utils/getLink';
import LangDropdown from '../components/LangDropdown/LangDropdown';
import { LangContext } from '../contexts/LangContext' 
import parse from 'html-react-parser';
import Popup from '../components/Popup/Popup';
import HowPopup from '../components/Popup/HowPopup';
import ErrorPopup from '../components/Popup/ErrorPopup';
import jwtDecode from 'jwt-decode';

function LandingPage() {
  const [streamerObj, setStreamerObj] = useState(null);
  const [ctaHref, setCtaHref] = useState('');
  const { streamer, deploymentId } = useParams();
  const { auth, authLoading} = useContext(ApiContext);
  const { lang } = useContext(LangContext);
  const [error, setError] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [howModal, setHowModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const [username, setUsername] = useState('');
  const [total, setTotal] = useState(15);

  useEffect(() => {
    const getStreamer = async () => {
      if (deploymentId) {
        try {
          const isValid = await fetch(`${getLink('api')}/valid?deploymentId=${deploymentId}`);
          const validResponse = await isValid.json();
          if (validResponse.error || !validResponse.isValid) {
            window.location = 'https://streamelements.com/';
            return;
          }

          if (validResponse.target) {
            setTotal(validResponse.target);
          }
          
          setStreamerObj(validResponse.channel);
        } catch (e) {
          console.log(e);
        }
      }
    };

    getStreamer();
  }, [deploymentId]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const queryError = query.get('error');
    if (queryError) {
      setError(queryError);
    };
    init()
  }, []);

  useEffect(() => {
    if (!authLoading) {
      const baseLink = getLink('LP');
      setCtaHref(`${baseLink}?streamer=${streamer}&channel=${streamerObj?.legacyId}&deploymentID=${deploymentId}&campaign=bp_level&action=login-participate&lang=${lang}`);

      if (auth) {
        const authObj = jwtDecode(auth);
        fetch(`${getLink('api')}/v1/viewer/${authObj.id}`, { headers: { Authorization: `Bearer ${auth}`}}).then(res => res.json()).then(res => {
          const current = res.CurrentBP ?? res.InitialBP ?? 0;
          const initial = res.InitialBP ?? 0;
          setCurrent(Math.max(current - initial, 0));
          setUsername(res.EpicDisplayName ?? '');
        }).catch(e => console.error(e));
      }
    }
  }, [authLoading, lang, streamer, streamerObj, setCurrent, auth, deploymentId]);


  const allowClick = () => {
  track({
      name: 'get_started_click',
      event: 'get_started_click',
      source: 'leaderboard',
      feature: 'leaderboard',
      product: 'sponsorship',
      fields: [['partnershipid', 'leaderboard-test']],
    }).catch(error => {console.log(error)})
  }

  return (
    <>
      <div className="header">
        <img className="logo" src={logo} alt="Fortnite logo"/>
        <LangDropdown/>
        {username && <div className="username">{username}</div>}
      </div>
      <div className={`wrapper ${lang === 'ru' ? 'russian' : ''}`}>
        <img className="heroImg" src={chars} alt="Fortnite characters"/>
        {streamerObj && 
          <div className="content">
            <img className="logo" src={logo} alt="Fortnite logo"/>
            {texts[lang].subtitle && <p className="subtitle">{texts[lang].subtitle}</p>}
            <div className="instructionWrapper">
              <div className={ auth ? "instructionBox completed" : "instructionBox"}>
                <div className="instructionNumberBox"><span className="instructionNumber">{current > 0? <img src={check} alt="logged in"/> : '1'}</span></div>
                <div className="instruction">{texts[lang].stageOne}</div>
              </div>
              <div className={ current >= total ? "instructionBox completed" : "instructionBox"}>
                <div className="instructionNumberBox"><span className="instructionNumber">{current >= total? <img src={check} alt="completed"/> : '2'}</span></div>
                <div className="instruction">
                  <div>{auth && texts[lang].download ? parse(texts[lang].download.replace('{streamer}', streamerObj?.platformDisplayName)) : texts[lang].stageTwo.replace('{streamer}', streamerObj?.platformDisplayName).replace('{total}', total)}</div>
                  {auth && <div className="progressBar"><div style={{ width: current*100/total + '%'}}></div></div>}
                  {auth && texts[lang].stageTwoProgress && <div className="instructionSub">{texts[lang].stageTwoProgress.replace('{current}', current).replace('{total}', total)}</div>}
                  {auth && texts[lang].stageTwoHowTo && (texts[lang].stageTwoHowTo.split('\n').map(text => (<div className="instructionSub thin link" onClick={() => setHowModal(true)}>{text}</div>)))}
                  {auth && texts[lang].stageTwoWarning && <div className="instructionSub thin">{texts[lang].stageTwoWarning}</div>}
                </div>
              </div>
              <div className={ current >= total ? "instructionBox completed" : "instructionBox"}>
                <div className="instructionNumberBox"><span className="instructionNumber">{current >= total? <img src={check} alt="completed"/> : '3'}</span></div>
                <div className="instruction">
                  <div className="downloadLink">{texts[lang].stageThree.replace('{streamer}', streamerObj?.platformDisplayName)}</div>
                  <img className="reward" src={wrap} alt="Reward"/>
                  {texts[lang].stageThreeSub && <div className="instructionSub">{texts[lang].stageThreeSub}</div>}
                </div>
              </div>
            </div>
            <div className="ctaWrapper">
              {auth ? 
              <span className="deleteSpan" onClick={() => setDeleteModal(true)}>{texts[lang].deleteText}</span> :
              <button 
                className="link-wrapper" 
                onClick={allowClick}
              >       
                <a href={ctaHref}>{texts[lang].btnText}</a>
              </button>}
            </div>
            {auth && texts[lang].notes?.length > 0 && (
              <div className="notes">{texts[lang].notes.map(d => (<p>{d}</p>))}</div>
            )}
          </div>}
        <footer className="StyledFooter">
          <span>{texts[lang].footer.createdBy}</span>
          <span className="StyledFooterSpan">
            <a className="StyledFooterLink" href="https://streamelements.com/contact">{texts[lang].footer.contact}</a>
            <a className="StyledFooterLink" href="https://streamelements.com/privacy">{texts[lang].footer.privacy}</a>
            <a className="StyledFooterLink" href={texts[lang].footer.faqLink}>{texts[lang].footer.faq}</a>
          </span>
        </footer>
        {error && <ErrorPopup text={error} closeFn={() => setError(false)}/>}
        {howModal && <HowPopup closeFn={() => setHowModal(false)}/>}
        {deleteModal && <Popup variant="delete" closeFn={() => setDeleteModal(false)}/>}
      </div>
    </>
  );
}

export default LandingPage;
